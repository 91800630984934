import React from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import { BusinessFormController } from '../controller/BussinesFormController';
import { withRouter } from 'react-router-dom';
import { Home } from '@material-ui/icons';
import { Utils } from '../../resources/Utils';
import { Verify } from '../../utils/Verify';
import { Urls } from '../../resources/Urls';

import Maps from '../../resources/MapsComponent';

class BussinesFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.verify = new Verify();
		this.businessFormController = new BusinessFormController();
		this.state = {
			bussinesVO: {
				view: 0,
				id: ' ',
				name: '',
				slogan: '',
				description: '',
				phone: '',
				contacEmail: '',
				googlemaps: '',
				instagramLink: '',
				facebookLink: '',
				twitterLink: '',
				mainSociamedia: '',
				Image1: '',
				Image3: '',
				status: '1',
				logoLink: '',
				profileLink: '',
				bannerLink: ''
			},
			catalogImgVOf: {
				catalog: ' ',
				code: ' ',
				description: '',
				id: ' ',
				status: ' '
			},
			catalogCatVOf: {
				id: '0'
			},
			pictureVO: {
				id: ' ',
				urlImg: ''
			},
			categorias: [],
			autoincrement: 1,
			isCheked: true,
			coordsMap: [],
			borrados: [],
			otherCat: '',
			ubicaciones: 1,
			images: {
				profileLink: '',
				logoLink: '',
				bannerLink: ''
			},
			newId: -1,
			attempts: 0,
			fail: false
		};

		this.handlerCancel = this.handlerCancel.bind(this);
		this.handlerAccept = this.handlerAccept.bind(this);

		this.handlerCancelEdit = this.handlerCancelEdit.bind(this);
		this.handlerAcceptEdit = this.handlerAcceptEdit.bind(this);
	}

	componentDidMount() {
		this.handlerMap = this.handlerMap.bind(this);

		if (this.verify.verifyToken() !== true) {
			this.props.history.push('/login');
			window.location.reload(false);
		}

		if (!this.verify.securityVerify('sudosu')) {
			this.props.history.push('/superAdminMenu');
		}

		this.init();
		this.setData();
	}

	init = async () => {
		const res = await this.businessFormController.findCatalog();
		this.setState({ categorias: res.data });

		const neweId = await this.businessFormController.getLast();
		this.setState({ newId: neweId });
		setTimeout(() => {
			const text = `Optimiza tus imágenes antes de subirlas: Tamaño máximo de archivo: 500 KB. Imagen de Banner: Debe tener 3000x1700 px.`;
			Utils.buttonSwalMessage({
				title: 'Recomendaciones',
				text: text,
				confirmButtonText: 'Entendido'
			});
		}, 3000);
	};

	setData() {
		if (this.props.form) {
			this.actualForm();
			setTimeout(() => {
				this.getPictureData();
				this.actualForm();
				this.getCoordsData();
			}, 200);
			setTimeout(() => {
				document.getElementById('profileLink').src =
					this.state.pictureVO.urlImg + '?' + Math.floor(Math.random() * 99999);
				document.getElementById('logoLink').src =
					this.state.bussinesVO.logoLink +
					'?' +
					Math.floor(Math.random() * 99999);
				document.getElementById('bannerLink').src =
					this.state.catalogImgVOf.description +
					'?' +
					Math.floor(Math.random() * 99999);
			}, 800);
		}
	}

	getCoordsData = async () => {
		const resp = await this.businessFormController.findCoords(
			this.state.bussinesVO.id
		);
		this.setState({ coordsMap: resp.data });

		let auxC = [];
		const coords = [];
		this.state.coordsMap.forEach((c) => {
			auxC = [
				{
					id: c.id,
					idBusiness: c.idBusiness,
					position: {
						lat: c.latitude,
						lng: c.longitude
					},
					key: c.id,
					animation: 2
				}
			];

			coords.push.apply(coords, auxC);
			auxC = [];
		});

		this.setState({ coordsMap: coords });
	};

	handlerMap(state, val, borrado) {
		let newMark = [];
		if (val) {
			if (borrado !== -1) {
				const id = [borrado];
				this.state.borrados.push.apply(this.state.borrados, id);
			}
		} else {
			newMark = {
				position: {
					lat: state[0][0],
					lng: state[0][1]
				},
				id: state[0][2]
			};
		}

		this.setState({ coordsMap: [newMark] });
	}

	getPictureData = async () => {
		const resp = await this.businessFormController.findPicture(
			this.state.bussinesVO.id
		);
		this.setState({ pictureVO: resp.data });
	};

	back = (_) => {
		window.history.back();
	};

	menu = (_) => {
		this.verify.MenuTypeUser(this.props.history);
	};

	handleChange = (e) => {
		this.setState({
			bussinesVO: {
				...this.state.bussinesVO,
				[e.target.name]:
					e.target.name === 'phone'
						? e.target.value.replace(/\D/g, '')
						: e.target.value
			}
		});
	};

	handleChangeCat = (e) => {
		this.setState({
			catalogCatVOf: {
				...this.state.catalogCatVOf,
				id: e.target.value
			}
		});
	};

	handleChangeCatImg = (e) => {
		this.setState({
			catalogImgVOf: {
				...this.state.catalogImgVOf,
				description: e.target.value
			}
		});
	};

	handleChangePicImage = (e) => {
		this.setState({
			pictureVO: {
				...this.state.pictureVO,
				urlImg: e.target.value
			}
		});
	};

	handleChangeGeneral = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	};

	actualForm() {
		if (this.props.form) {
			this.setState({ view: 0 });
			this.setState({ bussinesVO: this.props.form });
			this.setState({ catalogCatVOf: this.props.form.catalogCatVO });
			this.setState({ catalogImgVOf: this.props.form.catalogImgVO });
		} else {
			this.setState({ view: 1 });
		}
	}

	setImageState = (e) => {
		const [file] = e.target.files;
		document.getElementById(e.target.name).src = URL.createObjectURL(file);

		this.setState({
			images: {
				...this.state.images,
				[e.target.name]: e.target.files[0]
			}
		});
	};

	validateEdit = (e) => {
		let typeAcc = '';
		if (this.props.form) {
			switch (e.target.name) {
				case 'profileLink':
					typeAcc = e.target.files[0].type.substr(-3);
					break;
				case 'bannerLink':
					typeAcc = e.target.files[0].type.substr(-3);
					break;
				default:
					typeAcc = 'png';
					break;
			}

			if (typeAcc === 'png' && e.target.files[0].type.includes('image/png')) {
				return true;
			} else {
				if (
					(typeAcc === 'jpg' || typeAcc === 'jpeg') &&
					(e.target.files[0].type.includes('image/jpg') ||
						e.target.files[0].type.includes('image/jpeg'))
				) {
					return true;
				} else {
					Utils.swalError(
						'Seleccione el mismo tipo de imagen que al insertar el negocio!'
					);
					return false;
				}
			}
		} else {
			if (e.target.name === 'logoLink') {
				if (e.target.files[0].type.includes('png')) {
					return true;
				} else {
					Utils.swalError('Debe seleccionar una imagen png para el logo!');
					return false;
				}
			} else {
				return true;
			}
		}
	};

	onImageChange = (e) => {
		if (e.target.files[0]) {
			if (
				e.target.files[0].name.endsWith('.jpg') ||
				e.target.files[0].name.endsWith('.jpeg') ||
				e.target.files[0].name.endsWith('.png')
			) {
				if (this.validateEdit(e)) {
					this.setImageState(e);
				}
			} else {
				Utils.swalError('¡Seleccione una imagen válida!');
			}
		} else {
			Utils.swalError('¡Seleccione una imagen!');
		}
	};

	sendImage = async (file, typeI, id, stateNmb) => {
		if (file) {
			const formData = new FormData();
			const newFile = new File([file], 'cuponealo-' + typeI + '-' + id, {
				type: file.type
			});
			formData.append('file', newFile);
			formData.append('dir', 'd');
			formData.append('secret', 'vivasoftitlan');
			formData.append('override', 'true');
			const repImgB = await this.businessFormController.updateImage(formData);

			if (repImgB.success) {
				Utils.swalSuccess('Imagen de ' + typeI + ' actualizada');

				this.setState(
					{
						images: {
							...this.state.images,
							[stateNmb]: ''
						}
					},
					() => {
						this.setState(
							{
								fail: false
							},
							() => {
								this.edit();
							}
						);
					}
				);
			} else {
				if (repImgB.message.includes('Row not found')) {
					Utils.swalError(
						'El archivo de la imagen de ' +
							typeI +
							' no existe! Asegúrese de usar el mismo formato de imagen (png, jpg, etc) que pegó.'
					);
				} else if (repImgB.message.includes('Missing input params')) {
					Utils.swalError(
						'Parece que algo salió mal al actualizar la imagen. Faltan datos.'
					);
				} else if (repImgB.message === 'Max size is 500KB') {
					Utils.swalError('La imagen no puede pesar más de 500KB.');
				} else {
					Utils.swalError(
						'¡Parece que falta algo! Asegúrese de enviar la imagen y el nombre correctamente.'
					);
				}
			}
		} else {
			return false;
		}

		this.setState(
			{
				images: {
					...this.state.images,
					[stateNmb]: ''
				}
			},
			() => {
				this.setState(
					{
						fail: true
					},
					() => {
						return false;
					}
				);
			}
		);
	};

	setImage = async (image, typeI, id, stateNmb) => {
		const formData = new FormData();
		const newFile = new File([image], 'cuponealo-' + typeI + '-' + id, {
			type: image.type
		});
		formData.append('file', newFile);
		formData.append('dir', 'd');
		formData.append('secret', 'vivasoftitlan');
		formData.append('override', 'false');
		const resProf = await this.businessFormController.sendImage(formData);

		if (!resProf.success) {
			if (resProf.message === 'internal server error') {
				Utils.swalError('Ah ocurrido un error al enviar la imagen.');
				return false;
			} else if (resProf.message === 'File already exists') {
				let extImg = '';

				if (image.name.endsWith('.jpg')) {
					extImg = 'jpg';
				} else if (image.name.endsWith('.jpeg')) {
					extImg = 'jpeg';
				} else if (image.name.endsWith('.png')) {
					extImg = 'png';
				}

				const props = {
					title:
						typeI === 'profile'
							? 'El archivo del perfil ya existe para el nuevo id.'
							: 'El archivo de ' + typeI + ' ya existe para el nuevo id.',
					text: 'Desea usar el link de la imagen ya existente?',
					confirmButtonText: 'Aceptar',
					cancelButtonText: 'Cancelar',
					data: {
						stateNmb: stateNmb,
						nameImg: 'cuponealo-' + typeI + '-' + id + '.' + extImg
					},
					handlerCanc: this.handlerCancel,
					handlerAcce: this.handlerAccept
				};

				Utils.buttonSwal(props);

				return false;
			} else if (resProf.message === 'Missing input params') {
				Utils.swalError(
					'¡Parece que falta algo! Asegúrese de enviar la imagen y el nombre correctamente.'
				);
				return false;
			} else {
				Utils.swalError('Ah ocurrido un error al enviar la imagen.');
				return false;
			}
		} else {
			this.setState(
				{
					bussinesVO: {
						...this.state.bussinesVO,
						[stateNmb]: resProf.data
					}
				},
				() => this.insertBusiness()
			);

			return true;
		}
	};

	handlerCancel = (data) => {
		this.setState({
			images: {
				...this.state.images,
				[data.stateNmb]: ''
			}
		});

		document.getElementById(data.stateNmb).src =
			Urls.filesApi.imgs + 'cuponealo-cargando2.png';
	};

	handlerAccept = (data) => {
		this.setState({
			bussinesVO: {
				...this.state.bussinesVO,
				[data.stateNmb]: Urls.filesApi.imgs + data.nameImg
			}
		});

		Utils.swalSuccess('Presione registrar nuevamente');
	};

	handlerCancelEdit = (data) => {
		return false;
	};

	handlerAcceptEdit = (data) => {
		this.editBusiness();
	};

	editBusiness = async (_) => {
		Utils.successToast('Editando negocio...');
		await this.businessFormController.update(this.state);
		Utils.swalSuccess('Se editó el negocio con éxito!!');

		setTimeout(() => {
			window.location.reload(true);
		}, 2500);
	};

	defaultEdit = async (event) => {
		event.preventDefault();
		this.edit();
	};

	edit = async (_) => {
		if (this.state.catalogCatVOf.id === '0') {
			await this.insertCategory(1);
		}

		if (this.state.coordsMap.length === 0) {
			Utils.swalError('¡Agregue un marcador con la dirección de su negocio!');
		} else if (this.state.bussinesVO.mainSociamedia === '') {
			Utils.swalError('¡Seleccione la red social principal de su negocio!');
		} else if (
			(this.state.bussinesVO.facebookLink === '') &
			(this.state.bussinesVO.instagramLink === '') &
			(this.state.bussinesVO.twitterLink === '')
		) {
			Utils.swalError(
				'¡Ingrese al menos el link o usuario de su red social principal!'
			);
		} else {
			if (this.state.images.profileLink !== '') {
				await this.sendImage(
					this.state.images.profileLink,
					'profile',
					this.state.bussinesVO.id,
					'profileLink'
				);
			} else if (this.state.images.logoLink !== '') {
				await this.sendImage(
					this.state.images.logoLink,
					'logo',
					this.state.bussinesVO.id,
					'logoLink'
				);
			} else if (this.state.images.bannerLink !== '') {
				await this.sendImage(
					this.state.images.bannerLink,
					'banner',
					this.state.bussinesVO.id,
					'bannerLink'
				);
			} else {
				if (!this.state.fail) {
					this.editBusiness();
				} else {
					const props = {
						title: 'Al parecer hubo un error previo en las imágenes.',
						text: 'Desea editar de todas formas?',
						confirmButtonText: 'Aceptar',
						cancelButtonText: 'Cancelar',
						data: '',
						handlerCanc: this.handlerCancelEdit,
						handlerAcce: this.handlerAcceptEdit
					};

					Utils.buttonSwal(props);
				}
			}
		}
	};

	insert = async (event) => {
		event.preventDefault();
		if (this.state.bussinesVO.view === 0) {
			if (this.state.catalogCatVOf.id === '0') {
				await this.insertCategory(0);
			} else {
				this.insertBusiness();
			}
		}
	};

	insertBusiness = async (_) => {
		if (this.state.coordsMap.length === 0) {
			Utils.swalError('¡Agregue un marcador con la dirección de su negocio!');
		} else if (this.state.bussinesVO.mainSociamedia === '') {
			Utils.swalError('¡Seleccione la red social principal de su negocio!');
		} else if (
			(this.state.bussinesVO.facebookLink === '') &
			(this.state.bussinesVO.instagramLink === '') &
			(this.state.bussinesVO.twitterLink === '')
		) {
			Utils.swalError(
				'¡Ingrese al menos el link o usuario de su red social principal!'
			);
		} else if (this.state.images.profileLink === '') {
			Utils.swalError('¡Seleccione una imagen para el perfil!');
		} else if (this.state.images.logoLink === '') {
			Utils.swalError('¡Seleccione una imagen para el qr!');
		} else if (this.state.images.bannerLink === '') {
			Utils.swalError('¡Seleccione una imagen para el banner!');
		} else {
			if (this.state.newId !== -1) {
				if (this.state.bussinesVO.profileLink === '') {
					await this.setImage(
						this.state.images.profileLink,
						'profile',
						this.state.newId,
						'profileLink'
					);
				} else if (this.state.bussinesVO.logoLink === '') {
					await this.setImage(
						this.state.images.logoLink,
						'logo',
						this.state.newId,
						'logoLink'
					);
				} else if (this.state.bussinesVO.bannerLink === '') {
					await this.setImage(
						this.state.images.bannerLink,
						'banner',
						this.state.newId,
						'bannerLink'
					);
				} else {
					Utils.successToast('Insertando negocio...');
					await this.businessFormController.insert(this.state);
					Utils.swalSuccess('Se insertó el negocio con éxito!!');
					this.props.history.push({
						pathname: '/businessGrid'
					});
					setTimeout(() => {
						window.location.reload(true);
					}, 4800);
				}
			}
		}
	};

	insertCategory = async (action) => {
		const findCat = await this.businessFormController.findCatalog();

		if (findCat.data.some((obj) => obj.description === this.state.otherCat)) {
			Utils.successToast('¡Ya existe esa categoría!\nPor favor selecciónala');
		} else {
			const catId = await this.businessFormController.insertar(this.state);
			this.setState(
				{
					catalogCatVOf: {
						id: catId
					}
				},
				() => (action > 0 ? null : this.insertBusiness())
			);
		}
	};

	cargarImagen = (x) => {
		const url = document.getElementById('Image' + x).value;
		document.getElementById('uploadPreview' + x).src = url;
	};

	buttonS() {
		if (this.props.form) {
			return (
				<input
					className='btn btn-success btn-block'
					type='submit'
					value='Editar negocio'
				/>
			);
		} else {
			return (
				<input
					className='btn btn-success btn-block'
					type='submit'
					value='Registrar negocio'
				/>
			);
		}
	}

	formS() {
		if (this.props.form) {
			return (
				<form onSubmit={this.defaultEdit} encType='multipart/form-data'>
					{this.renderForm()}
				</form>
			);
		} else {
			return (
				<form onSubmit={this.insert} encType='multipart/form-data' noValidate>
					{this.renderForm()}
				</form>
			);
		}
	}

	tittleS() {
		if (this.props.form) {
			return <h4 className='text-center'> Edición de negocio </h4>;
		} else {
			return <h4 className='text-center'> Alta de negocio </h4>;
		}
	}

	renderForm() {
		return (
			<table className='table table-dark table-striped'>
				<tbody>
					<tr>
						<td>
							<div className='form-row'>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Nombre:</label>
									</div>
									<div className='p-2'>
										<input
											className='form-control'
											type='text'
											name='name'
											maxLength={45}
											placeholder='Ejemplo: Oxxo Col Centro'
											defaultValue={this.state.bussinesVO.name}
											required
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Slogan:</label>
									</div>
									<div className='p-2'>
										<input
											className='form-control'
											type='text'
											name='slogan'
											maxLength={45}
											placeholder='Su slogan aquí'
											value={this.state.bussinesVO.slogan}
											required
											onChange={this.handleChange}
										/>
									</div>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<label>Descripción:</label>
							<textarea
								className='form-control'
								type='text'
								maxLength={200}
								name='description'
								value={this.state.bussinesVO.description}
								onChange={this.handleChange}
							/>
						</td>
					</tr>

					<tr>
						<td>
							<div className='form-row'>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Teléfono:</label>
									</div>
									<div className='p-2'>
										<input
											className='form-control'
											type='tel'
											name='phone'
											placeholder='xxxxxxxxxx'
											maxLength={10}
											min='1000000000'
											pattern='[0-9]{10}'
											title='Proporcione solo 10 dígitos en números del 0 al 9'
											value={this.state.bussinesVO.phone}
											required
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Email de contacto:</label>
									</div>
									<div className='p-2'>
										<input
											className='form-control'
											type='email'
											name='contacEmail'
											maxLength={45}
											onChange={this.handleChange}
											defaultValue={this.state.bussinesVO.contacEmail}
											required
											placeholder='example@host.com'
											pattern='[a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$'
											title='Letras a-z, números 0-9 y simbolos ._%+- "ejemplo@dominio.gov"'
										/>
									</div>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div className='form-row'>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Seleccione la categoría del negocio:</label>
									</div>
									<div className='p-2'>
										<select
											className='form-control'
											aria-label='.form-select-lg example'
											name='catId'
											value={this.state.catalogCatVOf.id}
											required
											onChange={this.handleChangeCat}
										>
											<option key={0} value={0}>
												Nueva categoría
											</option>
											{this.state.categorias.map((c) => (
												<option key={c.id} value={c.id}>
													{c.description}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='col-md-6 p-2'>
									<div className='p-2'>
										<label>Otra categoría:</label>
									</div>
									<div className='p-2'>
										{this.state.catalogCatVOf.id === '0' ? (
											<input
												className='form-control'
												type='text'
												name='otherCat'
												maxLength={45}
												value={this.state.otherCat}
												required
												onChange={this.handleChangeGeneral}
											/>
										) : (
											<input
												className='form-control'
												readOnly
												type='text'
												name='otherCat'
												value={this.state.otherCat}
												required
												maxLength={45}
												onChange={this.handleChangeGeneral}
											/>
										)}
									</div>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div className='row pt-4 pl-4'>
								<div className='col-lg-9 col-md-6 col-sm-4'>
									<label>Ubicación:</label>
								</div>
							</div>
							<div className='row p-2 justify-content-center p-2'>
								<div className='col-lg-10 col-md-10 cols-sm-10 mapCaja'>
									<Maps
										data={this.state.coordsMap}
										handler={this.handlerMap}
										mode={'Edit'}
									/>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div className='col-md-12 p-2'>
								<label>
									Escriba el enlace a sus redes sociales y seleccione la
									principal:
								</label>
								<br />
								<br />
								<label>Usuario de Twitter:</label>
								<div className='input-group'>
									<div className='input-group-prepend'>
										<div className='input-group-text'>
											<input
												type='radio'
												aria-label='Radio button for following text input'
												name='mainSociamedia'
												id='mainSociamedia'
												value='Twitter'
												checked={
													this.state.bussinesVO.mainSociamedia === 'Twitter'
												}
												onChange={this.handleChange}
											/>
										</div>
									</div>
									<input
										type='text'
										className='form-control'
										aria-label='Text input with radio button'
										name='twitterLink'
										placeholder='Ejemplo: usuario'
										value={this.state.bussinesVO.twitterLink}
										onChange={this.handleChange}
									/>
								</div>
								<br />
								<label>Enlace de Facebook:</label>
								<div className='input-group'>
									<div className='input-group-prepend'>
										<div className='input-group-text'>
											<input
												type='radio'
												aria-label='Radio button for following text input'
												name='mainSociamedia'
												id='mainSociamedia'
												value='Facebook'
												checked={
													this.state.bussinesVO.mainSociamedia === 'Facebook'
												}
												onChange={this.handleChange}
											/>
										</div>
									</div>
									<input
										type='text'
										className='form-control'
										aria-label='Text input with radio button'
										name='facebookLink'
										placeholder='Ejemplo: https://www.facebook.com/softitlanmx'
										value={this.state.bussinesVO.facebookLink}
										onChange={this.handleChange}
									/>
								</div>
								<br />
								<label>Usuario de TikTok:</label>
								<div className='input-group'>
									<div className='input-group-prepend'>
										<div className='input-group-text'>
											<input
												type='radio'
												aria-label='Radio button for following text input'
												name='mainSociamedia'
												id='mainSociamedia'
												value='Instagram'
												checked={
													this.state.bussinesVO.mainSociamedia === 'Instagram'
												}
												onChange={this.handleChange}
											/>
										</div>
									</div>
									<input
										type='text'
										className='form-control'
										aria-label='Text input with radio button'
										name='instagramLink'
										placeholder='Ejemplo: usuario'
										value={this.state.bussinesVO.instagramLink}
										onChange={this.handleChange}
									/>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div className='form-row'>
								<div className='col-md-6 p-2'>
									<div className='text-center'>
										<div>
											<label>Imagen de perfil:</label>
										</div>
										<div className='p-4'>
											<img
												id='profileLink'
												src={Urls.filesApi.imgs + 'cuponealo-cargando2.png'}
												className='img-thumbnail'
												width='75%'
												alt='Vista previa'
											/>
										</div>
									</div>
									<div>
										<div className='custom-file'>
											<input
												type='file'
												className='custom-file-input'
												id='customFile'
												name='profileLink'
												onChange={this.onImageChange}
											/>
											<label className='custom-file-label' htmlFor='customFile'>
												Selecciona una imagen:
											</label>
										</div>
									</div>
								</div>
								<div className='col-md-6 p-2'>
									<div className='text-center'>
										<div>
											<label>Logo para el QR:</label>
										</div>
										<div className='p-4'>
											<img
												id='logoLink'
												src={Urls.filesApi.imgs + 'cuponealo-cargando2.png'}
												className='img-thumbnail'
												width='75%'
												alt='Vista previa'
											/>
										</div>
									</div>
									<div>
										<div className='input-group mb-3'>
											<div className='custom-file'>
												<input
													type='file'
													className='custom-file-input'
													id='customFile'
													name='logoLink'
													lang='es'
													accept='.png'
													onChange={this.onImageChange}
												/>
												<label
													className='custom-file-label'
													htmlFor='customFile'
												>
													Selecciona una imagen:
												</label>
											</div>
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td>
							<div className='form-row'>
								<div className='col p-2'>
									<div>
										<label>Banner:</label>
									</div>
									<div className='text-center'>
										<img
											id='bannerLink'
											src={Urls.filesApi.imgs + 'cuponealo-cargando2.png'}
											className='img-fluid'
											alt='Vista previa'
										/>
									</div>
									<br />
									<div className='input-group mb-3'>
										<div className='custom-file'>
											<input
												type='file'
												className='custom-file-input'
												id='customFile'
												name='bannerLink'
												onChange={this.onImageChange}
											/>
											<label className='custom-file-label' htmlFor='customFile'>
												Selecciona una imagen:
											</label>
										</div>
									</div>
								</div>
							</div>
						</td>
					</tr>

					<tr>
						<td colSpan='2'>
							<div>{this.buttonS()}</div>
						</td>
					</tr>
				</tbody>
			</table>
		);
	}

	render() {
		return (
			<div className='container py-md-4 py-lg-4 py-sm-2 py-xl-5'>
				<div className='row justify-content-center'>
					<div className='col-sd-12 col-md-10 col-lg-12 align-self-center'>
						<div className='card shadow bg-dark text-white'>
							<div className='card-header'>{this.tittleS()}</div>

							<div className='card-body'>
								<div className='container-fluid'>
									<div className='table-responsive p-2'>{this.formS()}</div>
								</div>
							</div>

							<div className='card-footer'>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col-2'>
											<ArrowBackIos onClick={this.back} />
										</div>
										<div className='col-8'></div>
										<div className='col-2'>
											<Home onClick={this.menu} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default withRouter(BussinesFormComponent);
