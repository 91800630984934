import { Urls } from '../../resources/Urls';
import { Client } from '../../resources/Client';

export class ClientGridController {
	async findFilter(tableData) {
		let idBusiness;
		if (
			sessionStorage.getItem('role') === 'sudosu' &&
			(sessionStorage.getItem('idBusiness') === null ||
				sessionStorage.getItem('idBusiness') === undefined)
		) {
			idBusiness = 1;
		} else {
			idBusiness = sessionStorage.getItem('idBusiness');
		}

		const res = await Client.GET({
			url: Urls.clientsApi.findFilter,
			params: {
				page: tableData.page,
				limit: tableData.limit,
				id: idBusiness,
				text: tableData.text,
				column: tableData.column
			}
		});
		return res;
	}

	async findAllFilter(tableData) {
		let idBusiness;
		if (
			sessionStorage.getItem('role') === 'sudosu' &&
			(sessionStorage.getItem('idBusiness') === null ||
				sessionStorage.getItem('idBusiness') === undefined)
		) {
			idBusiness = 1;
		} else {
			idBusiness = sessionStorage.getItem('idBusiness');
		}

		const res = await Client.GET({
			url: Urls.clientsApi.findAllFilter,
			params: {
				page: tableData.page,
				limit: tableData.limit,
				id: idBusiness,
				text: tableData.text
			}
		});
		return res;
	}

	async findAllCientsActive(tableData) {
		let idBusiness;
		if (
			sessionStorage.getItem('role') === 'sudosu' &&
			(sessionStorage.getItem('idBusiness') === null ||
				sessionStorage.getItem('idBusiness') === undefined)
		) {
			idBusiness = 1;
		} else {
			idBusiness = sessionStorage.getItem('idBusiness');
		}

		const res = await Client.GET({
			url: Urls.clientsApi.findAllClientsActive,
			params: {
				page: tableData.page,
				limit: tableData.limit,
				id: idBusiness,
				text: tableData.text
			}
		});
		return res;
	}

	async delete(id) {
		Client.POST({
			url: Urls.clientsApi.edit,
			alert: 'show',
			params: {
				id
			},
			data: {
				status: '0'
			}
		});
	}

	async active(id) {
		Client.POST({
			url: Urls.clientsApi.edit,
			alert: 'show',
			params: {
				id
			},
			data: {
				status: '1'
			}
		});
	}

	async edit(forme) {
		const res = await Client.POST({
			url: Urls.clientsApi.edit,
			alert: 'show',
			params: {
				id: forme.id
			},
			data: {
				name: forme.personVO.name,
				surname: forme.personVO.surname,
				phone: forme.personVO.phone,
				birthdate: forme.personVO.birthdate,
				email: forme.email
			}
		});
		return res;
	}

	async findAllActive(tableData) {
		const res = await Client.GET({
			url: Urls.clientsApi.findAllActive,
			alert: 'show',
			params: {
				page: tableData.page,
				limit: tableData.limit
			}
		});
		return res;
	}

	findAllClients = async (params) =>
		await Client.GET({
			url: Urls.clientsApi.findAllClients,
			alert: 'show'
		});
}
